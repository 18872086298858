import React from 'react';
import useAuth from '../../components/use-auth';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import OrderForm from '../../components/order-form';

const AddOrderPage = ({ path }) => {
  const auth = useAuth();
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Add Order" />
      <OrderForm />
    </Layout>
  )
}

export default AddOrderPage;
