import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate, Link } from 'gatsby'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { EP, callApi } from '../api';
import TransitionAlert from './transition-alert';
import useFormInput from './use-form-input';
import { languages } from './i18n';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
  checkbox: {
    fontSize: '0.8em',
  },
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }
}));

function OrderForm() {
  const { t } = useTranslation('orderForm');
  const classes = useStyles();
  const id = useFormInput();
  const email = useFormInput();
  const lang = useFormInput();
  const name = useFormInput();
  const numItems = useFormInput();
  const smsEnabled = useFormInput(false);
  const phone = useFormInput();
  const storedLocation = useFormInput();
  const [create, setCreate] = useState({ isCreating: false, isError: false, error: null }); 
  const isInvalidForm = (
    !id.isValid 
    || !email.isValid
    || smsEnabled.value && !phone
  ); 
  const handleOnCreate = async (e) => {
    e.preventDefault();
    setCreate({ ...create, isCreating: true });
    const body = {
      id: id.value,
      email: email.value,
      lang: lang.value,
      name: name.value,
      numItems: numItems.value,
      phone: smsEnabled.value ? phone.value : '',
      storedLocation: storedLocation.value,
    };
    try {
      await callApi(EP.orders.post, { body });
      navigate('/');
    } catch(error) {
      const message = error.response && error.response.data && error.response.data.message; 
      setCreate({ ...create, isCreating: false, isError: true, error: message });
    }
  }
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('addOrder')}
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            inputProps={{
              readOnly: Boolean(create.isCreating),
            }}
            autoFocus
            fullWidth
            id="id"
            label={t('orderNumber')}
            margin="normal"
            name="id"
            required
            type="text"
            variant="outlined"
            value={id.value}
            onChange={id.onChange}
          />
          <TextField
            inputProps={{
              readOnly: Boolean(create.isCreating),
            }}
            fullWidth
            id="name"
            label={t('customerName')}
            margin="normal"
            name="name"
            required
            type="text"
            variant="outlined"
            value={name.value}
            onChange={name.onChange}
          />
          <TextField
            inputProps={{
              readOnly: Boolean(create.isCreating),
            }}
            fullWidth
            id="email"
            label={t('emailAddress')}
            margin="normal"
            name="email"
            required
            type="email"
            variant="outlined"
            value={email.value}
            onChange={email.onChange}
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={smsEnabled.value}
                  onChange={smsEnabled.onChange}
                  name="smsEnabled"
                  color="primary"
                />
              }
              label={<Typography className={classes.checkbox}>{t('smsEnabled')}</Typography>}
            />
          </FormGroup>
          { smsEnabled.value && (
            <TextField 
              inputProps={{
                readOnly: Boolean(create.isCreating),
              }}
              fullWidth
              id="phone"
              label={t('mobilePhone')}
              margin="normal"
              name="phone"
              type="text"
              variant="outlined"
              value={phone.value} 
              onChange={phone.onChange}
            />
          )}
          <TextField
            inputProps={{
              readOnly: Boolean(create.isCreating),
            }}
            fullWidth
            id="numItems"
            label={t('numItems')}
            margin="normal"
            name="numItems"
            type="text"
            variant="outlined"
            value={numItems.value}
            onChange={numItems.onChange}
          />
          <TextField
            inputProps={{
              readOnly: Boolean(create.isCreating),
            }}
            fullWidth
            id="storedLocation"
            label={t('storedLocation')}
            margin="normal"
            name="storedLocation"
            type="text"
            variant="outlined"
            value={storedLocation.value}
            onChange={storedLocation.onChange}
          />
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id="lang">{t('preferredLanguage')}</InputLabel>
            <Select
              labelId="lang"
              id="lang"
              value={lang.value}
              onChange={lang.onChange}
              label={t('preferredLanguage')}
            >
              { languages.map(({code, label}) => <MenuItem key={code} value={code}>{label}</MenuItem>) }
            </Select>
          </FormControl>
          <div>
          <TransitionAlert className={classes.alert} severity="error" isOpen={create.isError}>
            { create.error 
              ? <p>{create.error}</p>
              : <p dangerouslySetInnerHTML={{__html: t('genericError', {interpolation: {escapeValue: false}})}} />
            }
          </TransitionAlert>
          </div>
          <Grid container spacing={1} className={classes.buttons}>
            <Grid item xs={6}>
              <Button
                disabled={create.isCreating || isInvalidForm}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleOnCreate}
              >
                { create.isCreating ? t('sending') : t('cta') }
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button 
                component={Link} 
                type="button"
                fullWidth
                variant="outlined"
                to="/"  
              >
                 {t('cancel')} 
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default OrderForm;
